'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import Clearfix from 'react-bootstrap/lib/Clearfix';
import Grid from 'react-bootstrap/lib/Grid';
import { ReCaptcha } from 'react-recaptcha-v3';

import { signup } from '../../actions/influencer';
import Alerts from '../Alerts';
import { setMetatags } from '../../actions/settings';
import { FormGroupHelper, getReCaptchaSiteKey, page_metas } from '../Helper';

const init_state = {
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  socials: {
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: ''
  },
  password: '',
  repeatpassword: '',
  subdomain: '',
  phonenumber: '',
  gtoken: '',
  siteKey: '',
  action: 'signup',
  inf_signup: 0,
  intro_bg: ''
}

class InfluencerSignup extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.influencer_signup;
    this.props.setMetatags(metatags);
    this.state = Object.assign({}, init_state, {
      siteKey: getReCaptchaSiteKey(),
    });
  }
  componentDidMount() {
    const introbgnum = Math.floor(Math.random() * 21) + 1;
    this.setState({ intro_bg: '/assets/template/images/fullbackgrounds/intro_' + introbgnum + '.jpg' });

  }
  componentWillUnmount() {
    this.props.clearAlerts();
  }

  verifyCallback(recaptchaToken) {
    this.setState({ gtoken: recaptchaToken });
  }

  updateToken() {
    this.recaptcha.execute();
  }

  handleChange(event) {
    const name = event.target.name;
    if (name.indexOf('_') > 0) {
      let splited = name.split('_');
      let s1 = splited[0];
      let s2 = splited[1];
      this.state[s1][s2] = event.target.value;
      this.setState(this.state);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }
  static getDerivedStateFromProps(props, state) {
    let newState = {};
    if (!props.loading && state.inf_signup != props.inf_signup) {
      const intro_bg = newState.intro_bg ? newState.intro_bg : '';
      newState = Object.assign({}, state, init_state);
      newState.intro_bg = intro_bg;
      newState.inf_signup = props.inf_signup;
    }
    if (Object.keys(newState).length > 0) {
      return newState;
    }
    return null;
  }
  // componentWillReceiveProps(nextProps) {
  //   if (this.props.loading != nextProps.loading && !nextProps.loading) {
  //     const current_state = this.state;
  //     const state = nextProps.inf_signup ? Object.assign({}, current_state, init_state) : current_state;
  //     this.setState(Object.assign(state, {
  //       inf_signup: nextProps.inf_signup,
  //     }));
  //     if (Object.keys(nextProps.alerts).length && nextProps.alerts.error) {
  //       executeReCaptcha(this.state.siteKey, this.state.action, this.verifyCallback.bind(this));
  //     }
  //   }
  // }
  /*componentWillReceiveProps(nextProps) {
    if (this.props.loading != nextProps.loading && !nextProps.loading) {
      const current_state = this.state;
      const state = nextProps.inf_signup ? Object.assign({}, current_state, init_state) : current_state;
      this.setState(Object.assign(state, {
        inf_signup: nextProps.inf_signup,
      }));
      if (Object.keys(nextProps.alerts).length && nextProps.alerts.error) {
        executeReCaptcha(this.state.siteKey, this.state.action, this.verifyCallback.bind(this));
      }
    }
  }*/
  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.updateToken();
    this.props.signUp(this.state, this.state.gtoken);
  }

  render() {
    const intro_styles = this.state.intro_bg != '' ? {
      backgroundImage: 'url("images/overlay.png"), url(' + this.state.intro_bg + ')',
    } : {};

    return (
      <Grid fluid>
        <section id="intro" className="main style1 dark fullscreen" style={intro_styles}>
          {/* <section id="intro" className="main style1 dark fullscreen" > */}
          <Grid id="become-influencer">
            <Row>
              <Col xs={12} sm={12} md={12}>
                {/* <PageHeader>Become Influencer</PageHeader> */}
                <Alerts alerts={this.props.alerts} />
              </Col>
              {(this.state.inf_signup) ? ''
                : <Form onSubmit={this.handleSubmit.bind(this)} autoComplete="off" >
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='firstname'
                      name="firstname"
                      type="text"
                      label="First Name"
                      placeholder="John"
                      autoComplete="off"
                      value={this.state.firstname}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='lastname'
                      name="lastname"
                      type="text"
                      label="Last Name"
                      placeholder="Smith"
                      autoComplete="off"
                      value={this.state.lastname}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='email'
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="myemail@example.com"
                      autoComplete="off"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='username'
                      name="username"
                      type="text"
                      label="Username"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.username}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='password'
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="********"
                      autoComplete="off"
                      value={this.state.password}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='repeatpassword'
                      name="repeatpassword"
                      type="password"
                      label="Repeat Password"
                      placeholder="********"
                      autoComplete="off"
                      value={this.state.repeatpassword}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroup>
                      <ControlLabel>Subdomain</ControlLabel>
                      <InputGroup>
                        <FormControl
                          id='subdomain'
                          name="subdomain"
                          type="text"
                          placeholder="subdomain"
                          autoComplete="off"
                          value={this.state.subdomain}
                          onChange={this.handleChange.bind(this)} />
                        <InputGroup.Addon>.snkr.ly</InputGroup.Addon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='phonenumber'
                      name="phonenumber"
                      type="text"
                      label="Phone Number"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.phonenumber}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='socials-facebook'
                      name="socials_facebook"
                      type="text"
                      label="Facebook Username"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.socials.facebook}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='socials-twitter'
                      name="socials_twitter"
                      type="text"
                      label="Twitter Username"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.socials.twitter}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='socials-instagram'
                      name="socials_instagram"
                      type="text"
                      label="Instagram Username"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.socials.instagram}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    <FormGroupHelper
                      id='socials-youtube'
                      name="socials_youtube"
                      type="text"
                      label="Youtube Username"
                      placeholder=""
                      autoComplete="off"
                      value={this.state.socials.youtube}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Col>
                  <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={this.state.siteKey}
                    action={this.state.action}
                    verifyCallback={this.verifyCallback.bind(this)}
                  />
                  <Clearfix />
                  <Col xs={12} sm={12} md={12}>
                    <FormGroup>
                      <Button type="submit" role="button" bsStyle="invite" className="pull-right">Request Invite</Button>
                    </FormGroup>
                  </Col>
                </Form>}
            </Row>
          </Grid>
        </section>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    token: state.auth.token,
    user: state.auth.user,
    loading: state.settings.loading,
    inf_signup: state.settings.inf_signup ? state.settings.inf_signup : 0
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (data, gtoken) => {
      dispatch(signup(data, gtoken));
    },
    reset_influencer_signup: () => {
      dispatch({ type: 'INFLUENCER_CREATE_RESET' });
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencerSignup));

import React, { Component } from 'react';
import debounce from 'lodash.debounce'
import { Link, withRouter } from 'react-router-dom';
import { SearchApi } from '../actions/release';

class Sidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: props.open,
            searchValue: "",
            onText: false,
            dataSearch: {},
            recent: [],
            popular: [],
            isLoading: false,
            lastKeyCodeEnter: false,
            noItems: false,
        }
        this.makeApiRequestDelay = debounce(this.makeApiRequest, 500);
        this.getListPopular();
    }

    getNumberResult() {
        return (
            <div>{this.state.dataSearch.total == null ? 0 : this.state.dataSearch.total}</div>
        )
    }

    componentDidMount() {
        let recentSearch = localStorage.getItem('recentSearch');
        let recent = [];
        if (recentSearch != null && recentSearch != "") {
            recent = recentSearch.split(",")
        }

        this.setState({
            recent: recent,
        })
    }

    handleInputChange(event) {
        let value = event.target.value;
        this.setState({
            searchValue: value,
        })
    }

    handleInputKeyUp(event) {
        let keyCode = event.keyCode || event.which;
        let lastKeyCodeEnter = false;
        let value = event.target.value;
        if (keyCode === 13 && value.length > 0) { //press enter
            lastKeyCodeEnter = true;
        }
        this.changeSearchState(value, lastKeyCodeEnter);
    }

    changeSearchState(value, lastKeyCodeEnter) {
        this.setState({
            onText: value.length > 0,
            searchValue: value,
        }, () => {
            if (lastKeyCodeEnter) {
                let newRecent = this.renewRecentSearch(value, this.state.recent);
                localStorage.setItem('recentSearch', newRecent);
                this.props.closeSideBar()
                this.props.history.push(`/search?keyword=${value}&sort=popular`);
                this.setState({
                    recent: newRecent,
                    searchValue: "",
                })
            } else {
                this.makeApiRequestDelay(value, true)
            }
        })
    }

    changeSearchSuggestion(value) {
        let newRecent = this.renewRecentSearch(value, this.state.recent);
        localStorage.setItem('recentSearch', newRecent);
        this.setState({
            recent: newRecent,
        })

    }

    closeSideBar() {
        this.props.closeSideBar();
    }

    clearSearch() {
        this.setState({
            searchValue: "",
            onText: false,
            dataSearch: {}
        })
    }

    makeApiRequest(value, searchIfNoItems) {
        value = value.length > 0 ? value.toLowerCase().trim() : "";
        if (value.length <= 0 && searchIfNoItems) {
            return;
        }
        let body = {
            keyword: value,
            sort: {
                by: "popular",
                order: "desc"
            },
        }
        this.setState({
            isLoading: true,
            dataSearch: {}
        }, () => {
            SearchApi(body,
                (data) => {
                    let dataSearch = {};
                    dataSearch.total = data.total;
                    dataSearch.data = data.data;
                    this.setState({
                        dataSearch: dataSearch,
                        isLoading: false,
                    })

                    if (searchIfNoItems) {
                        this.setState({
                            noItems: !data.data.length,
                        })
                    }
                    if (data.data.length <= 0 && searchIfNoItems) {
                        this.makeApiRequest("", false);
                    }
                },
                (err) => {
                    this.setState({
                        isLoading: false,
                    })
                })
        })

    }

    getListPopular() {
        let body = {
            perpage: 5,
            sort: {
                by: "popular",
                order: "desc"
            }
        }
        SearchApi(body,
            (result) => {
                this.setState({
                    popular: result.data,
                })
            },
            (err) => {
                this.setState({
                    isLoading: false,
                })
            })
    }

    renewRecentSearch(newSearch, oldRecent) {
        let newRecent = [];
        newRecent.push(newSearch);
        for (let i = 0; i < oldRecent.length; i++) {
            let old = oldRecent[i];
            if (!newRecent.includes(old)) {
                newRecent.push(old)
            }

            if (newRecent.length >= 3) {
                break;
            }
        }
        return newRecent;
    }
    render() {
        if(!this.props.open){
            return (<></>)
        }
        const listItem = this.state.dataSearch.data ?
            this.state.dataSearch.data.map((item) =>
                <Link to={"/" + item.slug} key={item.id + ""} onClick={() => this.closeSideBar()}>
                    <span className="item" key={item.id + ""}>
                        <span className="img-wrapper"><img src={item.image} alt="" className="img" /></span>
                        <span className="name">{item.title} </span>
                        <span className="icon"><i className="fa fa-angle-right"></i></span>
                    </span>
                </Link>

            ) : null;

        const listPopular = this.state.popular ?
            this.state.popular.map((item) =>
                <Link to={"/" + item.slug} key={item.id + ""} onClick={() => this.closeSideBar()}>
                    <span className="item" key={item.id + ""}>
                        <span className="img-wrapper"><img src={item.image} alt="" className="img" /></span>
                        <span className="name">{item.title} </span>
                        <span className="icon"><i className="fa fa-angle-right"></i></span>
                    </span>
                </Link>
            ) : null;

        const listRecent = this.state.recent.map((recentItem, index) =>
            <Link to={`/search?keyword=${recentItem}&location=any&sort=popular&page=1`} key={"res-link-" + index} onClick={() => this.props.closeSideBar()}>
                <div className="item" key={"res-li-" + index} onClick={() => this.changeSearchSuggestion(recentItem)}>
                    <div className="name">
                        {recentItem}
                    </div>
                    <div className="icon">
                        <i className="fa fa-angle-right"></i>
                    </div>
                </div>
            </Link>
        );
        let searchResult = <></>
        if(this.state.searchValue != null && this.state.searchValue.length > 0){
            searchResult = <div className="search-result">
                                <Link to={"/search?keyword=" + this.state.searchValue} onClick={() => {
                                    this.props.closeSideBar();
                                    this.changeSearchSuggestion(this.state.searchValue);
                                }}>
                                    <div className="search-title">
                                        <div className="text">
                                            {this.state.searchValue}
                                        </div>
                                        <div className="number">{(this.state.dataSearch.total == null || this.state.noItems) ? 0 : this.state.dataSearch.total} RESULTS</div>
                                    </div>
                                </Link>
                                <div className="no-result-found" style={{ display: this.state.noItems ? "" : "none" }}>
                                    <span><b>We couldn’t find what you are looking for.</b></span><br />
                                    <span>Please try again or check out some of the links below.</span>
                                </div>
                                <div className="result-container" style={{ display: (this.state.onText == true && this.state.isLoading == false) ? "" : "none" }}>
                                    <h3 className="title">Trending</h3>
                                    <div className="list-item">
                                        {listItem}
                                    </div>
                                </div>
                            </div>
        }

        return (
            <div id="search-sidebar-wrapper" className={"open"}>
                <div className="overlay" onClick={() => this.closeSideBar()}></div>
                <nav id="search-sidebar">
                    <div className="search-bar-form form-horizontal">
                        <div className="form-group">
                            <span className="input-group-btn">
                                <button type="submit" name="search_submit" id="search-submit" aria-label="Search" className="btn search-submit">
                                    <i className="fa fa-search"></i>
                                </button>
                            </span>
                            <input name="search_term" aria-label="Search" type="text" maxLength="255" id='search-box' className="form-control" autoFocus autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" placeholder="type to search" onChange={(evt) => this.handleInputChange(evt)} onKeyUp={(evt) => this.handleInputKeyUp(evt)}
                                value={this.state.searchValue} />
                            <span className={"input-group-btn " + (this.state.onText ? "" : "hidden")} onClick={() => this.clearSearch()}>
                                <button type="button" className="btn search-clear">
                                    <i className="fa fa-backspace"></i>
                                </button>
                            </span>
                        </div>
                        <div className="btn btn-snkrly-dark btn-cancel-wrapper" onClick={() => this.closeSideBar()}><i className="social-icon fa fa-times"></i></div>
                    </div>
                    <div className="loader" style={{ display: !this.state.isLoading ? "none" : "" }}>
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                    <div className="search-suggestion" style={{ display: (this.state.searchValue != null && this.state.searchValue.length > 0) ? "none" : "" }}>
                        <div className="suggestion-container" style={{ display: (this.state.recent.length > 0) ? "" : "none" }}>
                            <h3 className="title">Recent Searches</h3>
                            <div className="list-item">
                                {listRecent}
                            </div>
                        </div>
                        <div className="search-result">
                            <div className="result-container" style={{ padding: "0px", marginTop: "40px" }}>
                                <h3 className="title">Trending</h3>
                                <div className="list-item">
                                    {listPopular}
                                </div>
                            </div>
                        </div>
                    </div>
                    {searchResult}
                </nav>
            </div>
        )
    }
}

export default withRouter(Sidebar);
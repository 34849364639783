'use strict';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


class InfluencerHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const active = { borderBottomColor: '#3f51b5' };
    const activeClass = 'active';
    let influencer_settings = {
      brand: '',
      slogon: "We\'re changing the way people buy and sell sneakers.",
      logo: '/logos/snkrly.png',
      title: '',
      styles: ''
    };
    let site_slogon = 'SNKR.LY';

    return (
      <header id="header">
        <h1><Link to="/" className="navbar-brand">SNKR.LY</Link></h1>
      </header>
    );
  }
}
InfluencerHeader.contextTypes = {
  router: PropTypes.object
};


const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(InfluencerHeader));

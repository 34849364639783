'use strict';
import url from 'url';
import qs from 'query-string';
import moment from 'moment';
import cookie from 'react-cookie';
// import { browserHistory } from 'react-router-dom';
import { API_URL } from './constants';
// Sign in with Facebook
export const facebookLogin = (influencer) => {
  const facebook = {
    url: API_URL() + '/auth/facebook',
    clientId: '347180575749667',
    redirectUri: API_URL() + '/auth/facebook/callback',
    authorizationUrl: 'https://www.facebook.com/v2.5/dialog/oauth',
    scope: 'email,user_location',
    width: 580,
    height: 400
  };

  return (dispatch) => {
    oauth2(facebook, dispatch)
      .then(openPopup)
      .then(pollPopup)
      .then(exchangeCodeForToken)
      .then(signIn)
      .then(closePopup);
  };
}

// Sign in with Twitter
export const twitterLogin = (influencer) => {
  const twitter = {
    url: API_URL() + '/auth/twitter',
    redirectUri: API_URL() + '/auth/twitter/callback',
    authorizationUrl: 'https://api.twitter.com/oauth/authenticate'
  };

  return (dispatch) => {
    oauth1(twitter, dispatch)
      .then(openPopup)
      .then(getRequestToken)
      .then(pollPopup)
      .then(exchangeCodeForToken)
      .then(signIn)
      .then(closePopup);
  };
}

// Sign in with Google
export const googleLogin = (influencer) => {
  const google = {
    url: API_URL() + '/auth/google',
    clientId: '814958990796-p1centjebv1k0htp3am05tfg5k10nl0k.apps.googleusercontent.com',
    redirectUri: API_URL() + '/auth/google/callback',
    authorizationUrl: 'https://accounts.google.com/o/oauth2/auth',
    scope: 'openid profile email',
    width: 452,
    height: 633
  };

  return (dispatch) => {
    oauth2(google, dispatch)
      .then(openPopup)
      .then(pollPopup)
      .then(exchangeCodeForToken)
      .then(signIn)
      .then(closePopup);
  };
}

// Link account
export const link = (provider, influencer) => {
  switch (provider) {
    case 'facebook':
      return facebookLogin(influencer);
    case 'twitter':
      return twitterLogin(influencer);
    case 'google':
      return googleLogin(influencer);
    default:
      return {
        type: 'LINK_FAILURE',
        alerts: [{ msg: 'Invalid OAuth Provider' }]
      }
  }
}

// Unlink account
export const unlink = (provider) => {
  return (dispatch) => {
    return fetch(API_URL() + '/unlink/' + provider)
      .then((response) => {
        return response.ok ? response.json() : {};
      }).then((json) => {
        if (json.success) {
          dispatch({
            type: 'UNLINK_SUCCESS',
            alerts: [{ msg: json.msg }]
          });
        } else {
          dispatch({
            type: 'UNLINK_FAILURE',
            alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
          });
        }
      });
  }
}

function oauth2(config, dispatch) {
  return new Promise((resolve, reject) => {
    const params = {
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      scope: config.scope,
      display: 'popup',
      response_type: 'code'
    };
    const url = config.authorizationUrl + '?' + qs.stringify(params);
    resolve({ url: url, config: config, dispatch: dispatch });
  });
}

function oauth1(config, dispatch) {
  return new Promise((resolve, reject) => {
    resolve({ url: 'about:blank', config: config, dispatch: dispatch });
  });
}

function openPopup({ url, config, dispatch }) {
  return new Promise((resolve, reject) => {
    const width = config.width || 500;
    const height = config.height || 500;
    const options = {
      width: width,
      height: height,
      top: window.screenY + ((window.outerHeight - height) / 2.5),
      left: window.screenX + ((window.outerWidth - width) / 2)
    };
    const popup = window.open(url, '_blank', qs.stringify(options, ','));

    if (url === 'about:blank') {
      popup.document.body.innerHTML = 'Loading...';
    }

    resolve({ window: popup, config: config, dispatch: dispatch });
  });
}

function getRequestToken({ window, config, dispatch }) {
  return new Promise((resolve, reject) => {
    return fetch(config.url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        redirectUri: config.redirectUri
      })
    }).then((response) => {
      return response.ok ? response.json() : {};
    }).then((json) => {
      if (json.success) {
        resolve({ window: window, config: config, requestToken: json, dispatch: dispatch });
      }
    });
  });
}

function pollPopup({ window, config, requestToken, dispatch }) {

  return new Promise((resolve, reject) => {
    const redirectUri = url.parse(config.redirectUri);
    const redirectUriPath = redirectUri.host + redirectUri.pathname;

    if (requestToken) {
      window.location = config.authorizationUrl + '?' + qs.stringify(requestToken);
    }

    const polling = setInterval(() => {
      if (!window || window.closed) {
        clearInterval(polling);
      }
      try {
        const popupUrlPath = window.location.host + window.location.pathname;
        if (popupUrlPath === redirectUriPath) {
          if (window.location.search || window.location.hash) {
            const query = qs.parse(window.location.search.substring(1).replace(/\/$/, ''));
            const hash = qs.parse(window.location.hash.substring(1).replace(/[\/$]/, ''));
            const params = Object.assign({}, query, hash);
            if (params.error) {
              dispatch({
                type: 'OAUTH_FAILURE',
                alerts: [{ msg: params.error }]
              });
            } else {
              resolve({ oauthData: params, config: config, window: window, interval: polling, dispatch: dispatch });
            }
          } else {
            dispatch({
              type: 'OAUTH_FAILURE',
              alerts: [{ msg: 'OAuth redirect has occurred but no query or hash parameters were found.' }]
            });
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in Internet Explorer.
      }
    }, 500);
  });
}

function exchangeCodeForToken({ oauthData, config, window, interval, dispatch }) {
  return new Promise((resolve, reject) => {
    const data = Object.assign({}, oauthData, config);
    return fetch(config.url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin', // By default, fetch won't send any cookies to the server
      body: JSON.stringify(data)
    }).then((response) => {
      return response.ok ? response.json() : {};
    }).then((json) => {
      if (json.success) {
        resolve({ token: json.data.token, user: json.data.user, window: window, interval: interval, dispatch: dispatch });
      } else {
        dispatch({
          type: 'OAUTH_FAILURE',
          alerts: Array.isArray(json.errors) ? json.errors : [json.errors]
        });
        closePopup({ window: window, interval: interval });
      }
    });
  });
}

function signIn({ token, user, window, interval, dispatch }) {
  return new Promise((resolve, reject) => {
    dispatch({
      type: 'OAUTH_SUCCESS',
      token: token,
      user: user
    });
    cookie.save('token', token, {
      expires: moment().add(1, 'week').toDate(),
      path: '/',
      sameSite: 'lax',
      // httpOnly: true,
      // secure: true
    });
    // browserHistory.push('/');
    resolve({ window: window, interval: interval });
  });

}


function closePopup({ window, interval }) {
  return new Promise((resolve, reject) => {
    clearInterval(interval);
    window.close();
    resolve();
  });
}


'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from 'react-bootstrap/lib/Grid';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Form from 'react-bootstrap/lib/Form';
import PageHeader from 'react-bootstrap/lib/PageHeader';
import { resetPassword } from '../../actions/auth';
import Alerts from '../Alerts';
import { setMetatags } from '../../actions/settings';
import { page_metas, FormGroupHelper, getReCaptchaSiteKey, loadReCaptcha } from '../Helper';
import { ReCaptcha } from 'react-recaptcha-v3';

class Reset extends Component {
    constructor(props) {
        super(props);
        const metatags = page_metas.reset;
        this.state = {
            pageTitle: metatags.title,
            password: '',
            confirm: '',
            gtoken: '',
            siteKey: getReCaptchaSiteKey(),
            action: 'reset'
        };
        this.props.setMetatags(metatags);
    }
    // componentWillUnmount() {
    //     this.props.clearAlerts();
    // }
    componentDidMount() {
        loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
      }
    
    componentDidUpdate() {
        if (this.props.alerts.length > 0 && nextProps.alerts.success) {
            this.props.clearAlerts();
            this.context.router.history.push('/login');
        }
    }

    verifyCallback(recaptchaToken) {
        this.setState({ gtoken: recaptchaToken });
    }
    updateToken() {
        this.recaptcha.execute();
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.props.clearAlerts();
        this.updateToken();
        this.props.resetPassword(this.state.password, this.state.confirm, this.props.match.params['token'], this.state.gtoken, this.props.influencer);

    }

    render() {
        return (
            <Grid className="login-container clear-paddings">
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <PageHeader>{this.state.pageTitle}</PageHeader>
                    <Alerts alerts={this.props.alerts} />
                    <FormGroupHelper
                        id='password'
                        name="password"
                        type="password"
                        label="New password"
                        placeholder="New password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                    />
                    <FormGroupHelper
                        id='confirm'
                        name="confirm"
                        type="password"
                        label="New password"
                        placeholder="Confirm password"
                        value={this.state.confirm}
                        onChange={this.handleChange.bind(this)}
                    />
                    <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={this.state.siteKey}
                        action={this.state.action}
                        verifyCallback={this.verifyCallback.bind(this)}
                    />
                    <FormGroup>
                        <Button type="submit" bsStyle="snkrly">Change Password</Button>
                    </FormGroup>
                </Form>
            </Grid>
        );
    }
}

Reset.contextTypes = {
    router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        alerts: state.alerts,
        influencer: state.influencer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (password, confirm, token, gtoken, influencer) => {
            dispatch(resetPassword(password, confirm, token, gtoken, influencer));
        },
        setMetatags: (metatags) => {
            dispatch(setMetatags(metatags));
        },
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reset));
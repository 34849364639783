'use strict';
import React, { Component } from 'react';

class ReadMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }
    toggleReadMore() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        let description =  '';
        if(this.props.description.length>0){
            let desc = this.props.description.split('\n\r');
            if(desc.length>1){
                const fist_part = desc[0];
                const second_part = desc.slice(1);
                const readMoreClasses = 'desc-read-more'+  (this.state.isOpen?' open':'')
                description = (<>
                                    <p dangerouslySetInnerHTML={{__html: fist_part}} />
                                    <p className={readMoreClasses} dangerouslySetInnerHTML={{__html: second_part.join('</p><p>')}} />
                                    <span onClick={() => this.toggleReadMore()} className="read-more">{this.state.isOpen?"read less":"read more"}</span>
                                </>);
            }else{
                description = <p dangerouslySetInnerHTML={{__html: desc.join('</p><p>')}} />
            }
            description = <div className='description'>{description}</div>
        }
        return description;
    }
};

export default ReadMore;
'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
// import { views } from '../../actions/influencer';

class InfluencerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      if (props.alerts.length > 0) {
        return {
          alerts: props.alerts,
        }
      }
    }
    return null;
  }

  /*componentWillReceiveProps(nextProps) {
    if (this.props.loading != nextProps.loading && !nextProps.loading) {
      const current_state = this.state;
      this.setState({
        alerts: nextProps.alerts,
      });
    }
  }*/

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  render() {
    return (
      <Switch>
        <Route path='/' render={(props) => (
          <Shop {...props} />
        )} />
        <Route path='/orders' render={(props) => (
          <Shop {...props} />
        )} />
      </Switch>
    );
  }
}

InfluencerPage.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    alerts: state.alerts,
    influencer: state.influencer ? state.influencer : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfluencerPage));

'use strict';
import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/lib/Row';

const Breadcrumb = (props) => {
  if(props.items.length == 0){
    return <></>
  }
  const divider = <span className="divider">/</span>;
  let position = 1;
  const breadcrumbHome = <li key={'home'} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><Link itemProp="item" to={'/'}><span itemProp="name">Home</span><meta itemProp="position" content={position} /></Link></li>
  const breadcrumbSize = props.items.length - 1;
  const breadcrumbList = props.items.map((item, index) => {
    position++;
    const title = (<><span key={index+100} itemProp="name">{item.title}</span><meta itemProp="position" content={position} /></>);
    const currentItem = index < breadcrumbSize?(<Link key={index+10} itemProp="item" to={item.url}>{title}</Link>):<>{title}</>; 
    const isActive = (index == breadcrumbSize) ?'active':'';
    return (<li key={index} className={isActive} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">{currentItem}</li>);
  });
  breadcrumbList.unshift(breadcrumbHome);
  return (
        <Row><ol className="breadcrumb"  itemScope itemType="https://schema.org/BreadcrumbList">{breadcrumbList}</ol></Row>
  );
}

export default Breadcrumb;

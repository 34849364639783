'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { checkout } from '../../actions/order';
import { countries, provinces, estimate_delivery, estimate_tax, setMetatags } from '../../actions/settings';
import Alerts from '../Alerts';
import Cart from './Cart';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { FormatCurrency, page_metas, getSelectOption } from '../Helper';
import { Col, Table, Form, FormControl, FormGroup, ControlLabel, HelpBlock, Button, Clearfix, PageHeader } from 'react-bootstrap';
import Select from 'react-select';

const MY_API_KEY = "AIzaSyD4bKGpM9kJEP_dLwcAQxW4oBOj3qfLbXw";
// const MY_API_KEY = "AIzaSyBMgV3k2Fby2grkC8MzVO01V_5MrOQ2ppg";
const componentForm = {
  country: { name: 'country', type: 'short_name', default: 840 },
  street_number: { name: 'street1', type: 'long_name', default: '' },
  street_address: { name: 'street1', type: 'long_name', default: '' },
  route: { name: 'street2', type: 'long_name', default: '' },
  sublocality_level_1: { name: 'street2', type: 'long_name', default: '' },
  locality: { name: 'city', type: 'long_name', default: '' },
  postal_code: { name: 'postal', type: 'long_name', default: '' },
  postal_code_suffix: { name: 'postal', type: 'long_name', default: '' },
  administrative_area_level_1: { name: 'province', type: 'long_name', default: '' }
};

const initState = {
  pageTitle: '',
  selected_country: 0,
  auto_province: '',
  address: {
    country: 840,
    province: 0,
    city: '',
    street1: '',
    street2: '',
    postal: '',
    phone: '',
    memo: ''
  },
  calcualte_tax: 0,
  search: '',
};

class Checkout extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.checkout;
    this.state = {
      pageTitle: metatags.title,
      selected_country: 0,
      auto_province: '',
      address: {
        firstname: '',
        lastname: '',
        email: '',
        country: 840,
        province: 0,
        city: '',
        street1: '',
        street2: '',
        postal: '',
        phone: '',
      },
      item: {},
      search: '',
      calcualte_tax: 0,
    };
    this.props.setMetatags(metatags);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.detectCountryShipping = this.detectCountryShipping.bind(this);
    this.handleTaxCalculate = this.handleTaxCalculate.bind(this);
  }
  componentWillUnmount() {
    this.props.clearAlerts();
    this.props.clearEstimateDelivery();
    this.props.clearEstimateTax();
  }

  componentDidMount() {
    if (this.props.countries.length == 0) {
      this.props.getCountries(this.props.token);
    }
    let address = {};
    if(Object.keys(this.props.user).length > 0){
      address = {
        firstname: this.props.user.firstname,
        lastname: this.props.user.lastname,
        email: this.props.user.email,
        country: this.props.user.address.country ? this.props.user.address.country : 840,
        province: this.props.user.address.province ? this.props.user.address.province : 0,
        city: this.props.user.address.city ? this.props.user.address.city : '',
        street1: this.props.user.address.street1 ? this.props.user.address.street1 : '',
        street2: this.props.user.address.street2 ? this.props.user.address.street2 : '',
        postal: this.props.user.address.postal ? this.props.user.address.postal : '',
        phone: this.props.user.address.phone ? this.props.user.address.phone : '',
        memo: ''
      };
    }
    let newState = {
      calcualte_tax: address?.postal?.length && Object.keys(this.props.item).length > 0 ? 1 : 0,
      item: this.props.item,
    };
    if(Object.keys(address).length > 0){
      newState.address = address;
    }
    this.setState(newState);

  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(this.props.item).length > 0) {
      if (!this.props.loading && this.state.address.country && this.state.address.country !== prevState.address.country) {
        this.detectCountryShipping(this.state.address.country);
        this.props.getProvinces(this.state.address.country, this.props.token);
      }
      if (this.state.address.province && this.state.address.province !== prevState.address.province) {
        const location = {
          country_id: this.state.address.country,
          state_id: this.state.address.province,
        }
        this.props.estimateDelivery(this.props.item.nid, location);
      }
      if (this.props.item.qty != prevProps.item.qty && !this.state.calcualte_tax) {
        this.handleTaxCalculate();
      }
      if ( !this.props.estimated_tax.is_loading && this.props.provinces.length > 0 && this.state.calcualte_tax) {
        this.props.estimateTax(this.props.item, this.state.address, this.props.influencer, this.props.token);
        this.setState({ calcualte_tax: 0 })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.item).length > 0) {
      if (!props.loading && props.countries.length > 0) {
        const country = parseInt(state.address.country);
        if (state.selected_country != country) {
          let ship_country = 'us';
          switch (country) {
            case 840:
              ship_country = 'us'
              break;
            case 124:
              ship_country = 'ca'
              break;
            default:
              ship_country = 'in'
              break;
          }
          if (props.item.shipping[ship_country] == -1) {
            props.no_shipping();
          } else if (state.address.country != country) {
            props.clearAlerts();
          }
          props.getProvinces(country, props.token);
          return {
            selected_country: country
          }
        } else if (state.auto_province != '' && props.provinces.length > 0) {
          let province = 0;
          for (var i = 0; i < props.provinces.length; i++) {
            if (state.auto_province == props.provinces[i].name.toLowerCase()) {
              province = props.provinces[i].id;
              break;
            }
          }
          return {
            auto_province: '',
            address: Object.assign(state.address, { province: province }),
          }
        }
      }
    }else{
      return {
        item: {}
      }
    }
    return null;
  }

  handleInputChange(event) {
    // event.preventDefault();
    const value = event.target.value;
    const address = Object.assign({}, this.state.address, {
      street1: value
    });
    this.setState({
      search: value,
      address: address
    });
  }

  handleSelectSuggest(place) {
    const current_state_address = Object.assign({}, this.state.address, initState.address);
    const default_state = Object.assign({}, this.state, {
      pageTitle: this.state.pageTitle,
      auto_province: initState.auto_province,
      search: initState.search,
      address: current_state_address,
      calcualte_tax: 1
    });
    let provinceval = '';
    let address = '';
    let postal_code = '';
    let new_state = default_state;
    let received_component = [];
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      received_component[addressType] = place.address_components[i];
    }

    for (var addressType in componentForm) {
      if (received_component[addressType]) {
        let element_name = componentForm[addressType].name;
        var val = received_component[addressType][componentForm[addressType].type];
        switch (addressType) {
          case 'country':
            var currentCountry = val.toUpperCase();
            var options = this.props.countries;
            for (var i = 0; i < options.length; i++) {
              if (options[i].iso_code_2 == currentCountry) {
                if (this.state.address.country != options[i].id) {
                  const country_value = parseInt(options[i].id);
                  this.setState({ selected_country: country_value, address: Object.assign({}, this.state.address, { [element_name]: country_value }) });
                  new_state.selected_country = country_value;
                  new_state.address.country = country_value;
                } else {
                  if (received_component['administrative_area_level_1']) {
                    provinceval = received_component['administrative_area_level_1'][componentForm['administrative_area_level_1'].type].toLowerCase();
                    for (var j = 0; j < this.props.provinces.length; j++) {
                      if (provinceval == this.props.provinces[j].name.toLowerCase()) {
                        const province = parseInt(this.props.provinces[j].id);
                        new_state.address.province = province;
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
            break;
          case 'administrative_area_level_1':
            if (provinceval == '') {
              new_state.auto_province = val.toLowerCase();
              new_state.address.province = 0;
            }
            break;
          case 'street_number':
            address += val;
            break;
          case 'route':
            address += ' ' + val;
            break;
          case 'street_address':
            address += ' ' + val;
            break;
          case 'postal_code':
            postal_code += val;
            break;
          case 'postal_code_suffix':
            postal_code += '-' + val;
            break;
          default:
            new_state.address = Object.assign({}, new_state.address, { [element_name]: val });
            break;
        }

      }
    }
    new_state.address = Object.assign({}, new_state.address, { street1: address, postal: postal_code });
    const is_calculate_tax = new_state.address.postal.length > 0 && (new_state.address.province > 0 || new_state.auto_province.length > 0) && new_state.address.street1.length > 0 ? 1 : 0;
    new_state.calcualte_tax = is_calculate_tax;
    this.setState(new_state);
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    let json = {};
    let current_state = this.state;
    json.address = Object.assign({}, current_state.address, {
      [name]: value
    });
    const state = Object.assign({}, current_state, json);
    this.setState(state);
  }

  handleTaxCalculate(event) {
    let calcualte_tax = 1;
    if (typeof event != 'undefined' && event.target) {
      if (event.target.name == 'postal' && event.target.value == this.state.address.postal) {
        calcualte_tax = 0;
      }
    }
    if (calcualte_tax != this.state.calcualte_tax) {
      this.setState({ calcualte_tax: calcualte_tax });
    }
    this.setState({ calcualte_tax: 1 });
  }

  handleSelectChange(name, is_user_input, event) {
    let current_state = Object.assign({}, this.state);
    const value = parseInt(event.value);
    switch (name) {
      case 'country':
        current_state.address = Object.assign({}, current_state.address, {
          province: 0
        });
        current_state = Object.assign({}, current_state, {
          selected_country: value
        });
        break;
      case 'province':
        current_state = Object.assign({}, current_state, {
          calcualte_tax: is_user_input ? 1 : 0
        });
        break;
    }
    current_state.address = Object.assign({}, current_state.address, {
      [name]: value
    });
    this.setState(current_state);
  }

  onKeyPress(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }
  detectCountryShipping(country) {
    let ship_country = 'us';
    switch (country) {
      case 840:
        ship_country = 'us'
        break;
      case 124:
        ship_country = 'ca'
        break;
      default:
        ship_country = 'in'
        break;
    }
    if (this.props.item.shipping[ship_country] == -1) {
      this.props.no_shipping();
    } else if (this.state.address.country != country) {
      this.props.clearAlerts();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.checkout(this.props.item, this.state.address, this.props.influencer, this.props.token);
  }
  FieldGroup({ id, label = false, help = false, ...props }) {
    return (
      <Col xs={12} sm={6} md={6}>
        <FormGroup controlId={id}>
          {label && <ControlLabel>{label}</ControlLabel>}
          <FormControl id={id} {...props} />
          {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
      </Col>
    );
  }

  FieldSelectGroup({ id, label = false, help = false, ...props }) {
    return (
      <Col xs={12} sm={6} md={6}>
        <FormGroup controlId={id}>
          {label && <ControlLabel>{label}</ControlLabel>}
          <Select id={id} {...props} />
          {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
      </Col >
    );
  }
  render() {
    if (!Object.keys(this.props.item).length) {
      return (
        <div id="checkout-wrapper">
          <PageHeader>Checkout</PageHeader>
          <Alerts alerts={this.props.alerts} />
          <div>You don't have product for checkout.</div>
        </div>
      )
    }
    const countries = this.props.countries.length > 0 ? this.props.countries.map((item, index) => {
      return { value: item.id, label: item.name }
    }) : [];
    const states = this.props.provinces.length > 0 ? this.props.provinces.map((item, index) => {
      return { value: item.id, label: item.name };
    }) : [];
    states.unshift({ value: 0, label: 'Select province' });
    let estimated_tax = 0;
    if (!this.props.estimated_tax.is_loading && this.props.estimated_tax.hasOwnProperty('tax')) {
      estimated_tax = this.props.estimated_tax.tax.amount;
    }
    let ship_country = 'us';
    switch (parseInt(this.state.address.country)) {
      case 840:
        ship_country = 'us'
        break;
      case 124:
        ship_country = 'ca'
        break;
      default:
        ship_country = 'in'
        break;
    }

    let total = 0;
    let shipping = 0;
    const tax_amount = estimated_tax;
    const item = this.props.item;
    const item_total_price = item.qty * (parseFloat(item.product_price.replace(/,/, "")) + parseFloat(item.size_price.replace(/,/, "")));
    total += item_total_price;
    let ship_text = '???';
    if (item.shipping[ship_country] > -1) {
      shipping += parseFloat(item.shipping[ship_country]);
      total += shipping;
      ship_text = <FormatCurrency value={shipping} />;
    }

    total += tax_amount;
    const tax_text = this.props.estimated_tax.is_loading ? <span id="tax-estimate-spinner"><i className={"fa fa-refresh fa-fw fa-spin"} onClick={this.handleTaxCalculate.bind(this)}></i><span className="sr-only">Loading...</span></span> : <FormatCurrency value={tax_amount} />;
    const selected_country = getSelectOption(this.state.address.country, countries);
    const selected_provice = getSelectOption(this.state.address.province, states);
    return (
      <div id="checkout-wrapper">
        <PageHeader>{this.state.pageTitle}</PageHeader>
        <Cart />
        <hr />
        <h4>Shipping Address</h4>
        <hr />
        <Alerts alerts={this.props.alerts} />
        <Form horizontal onKeyPress={this.onKeyPress.bind(this)} id="checkout-form" onSubmit={this.handleSubmit.bind(this)} className="form-horizontal row" >
          {this.FieldGroup({
            id: 'firstname',
            name: 'firstname',
            componentClass: "input",
            placeholder: 'First Name',
            value: this.state.address.firstname,
            onChange: this.handleChange.bind(this)
          })}
          {this.FieldGroup({
            id: 'lastname',
            name: 'lastname',
            componentClass: "input",
            placeholder: 'Last Name',
            value: this.state.address.lastname,
            onChange: this.handleChange.bind(this)
          })}
          {/* <Col xs={12} sm={6} md={6}>
            <FormGroup>
              <GoogleMapsLoader
                params={{
                  key: MY_API_KEY,
                  libraries: "places,geocode",
                }}
                render={googleMaps =>
                  googleMaps && (
                    <GooglePlacesSuggest
                      // children=""
                      googleMaps={googleMaps}
                      autocompletionRequest={{
                        input: this.state.search,
                        types: ['geocode']
                        // Optional options
                        // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                      }}
                      // Optional props
                      onSelectSuggest={this.handleSelectSuggest.bind(this)}
                      textNoResults="" // null or "" if you want to disable the no results item
                      customRender={prediction => (
                        <div className="addressItemWrapper">
                          {prediction
                            ? prediction.description
                            : ""}
                        </div>
                      )}
                    >
                      <FormControl componentClass="input" autoComplete="off" onChange={this.handleInputChange.bind(this)} name="street1" id="street1" placeholder="Street Address or P.O. Box" value={this.state.address.street1} />
                    </GooglePlacesSuggest>
                  )
                }
              />
            </FormGroup>
          </Col> */}
          {this.FieldGroup({
            id: 'street1',
            name: 'street1',
            componentClass: "input",
            placeholder: 'Street Address or P.O. Box',
            value: this.state.address.street1,
            onChange: this.handleChange.bind(this)
          })}          
          {this.FieldGroup({
            id: 'street2',
            name: 'street2',
            componentClass: "input",
            placeholder: 'Apt, Suite, Unit, Building, Floor',
            value: this.state.address.street2,
            onChange: this.handleChange.bind(this)
          })}
          {this.FieldSelectGroup({
            id: 'country',
            name: 'country',
            className: "react-select-container",
            classNamePrefix: 'react-select',
            isClearable: false,
            defaultValue: selected_country,
            value: selected_country,
            onChange: this.handleSelectChange.bind(this, 'country', true),
            options: countries
          })}
          {this.FieldSelectGroup({
            id: 'province',
            name: 'province',
            className: "react-select-container",
            classNamePrefix: 'react-select',
            isClearable: false,
            defaultValue: selected_provice,
            value: selected_provice,
            onChange: this.handleSelectChange.bind(this, 'province', true),
            options: states
          })}
          {this.FieldGroup({
            id: 'city',
            name: 'city',
            componentClass: "input",
            placeholder: 'City',
            value: this.state.address.city,
            onChange: this.handleChange.bind(this)
          })}
          {this.FieldGroup({
            id: 'postal',
            name: 'postal',
            componentClass: "input",
            placeholder: 'Postal Code',
            value: this.state.address.postal,
            onBlur: this.handleTaxCalculate.bind(this),
            onChange: this.handleChange.bind(this)
          })}
          {this.FieldGroup({
            id: 'email',
            type: "email",
            componentClass: "input",
            name: 'email',
            placeholder: 'Email',
            value: this.state.address.email,
            onChange: this.handleChange.bind(this)
          })}
          {this.FieldGroup({
            id: 'phone',
            name: 'phone',
            componentClass: "input",
            placeholder: 'Phone Number',
            value: this.state.address.phone,
            onChange: this.handleChange.bind(this),
          })}
          <Col xs={12} sm={12} md={12}>
            <Table align="right" className="pull-right" style={{ width: "auto" }}>
              <tbody>
                <tr className={this.props.estimated_delivery.length ? '' : 'hidden'}>
                  <td style={{ textAlign: "right", borderTop: "0", borderBottom: "1px" }}>Estimated Delivery</td>
                  <td style={{ borderTop: 0, borderBottom: "1px" }}>{this.props.estimated_delivery}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right", borderTop: "0" }}>Shipping</td>
                  <td style={{ borderTop: "0" }}>{ship_text}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Estimated Sales Tax</td>
                  <td>{tax_text}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Total</td>
                  <td><FormatCurrency value={total} /></td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={12} sm={12} md={12} >
            <FormGroup>
              <Button type="submit" bsStyle="snkrly" className="pull-right">Pay Now</Button>
            </FormGroup>
          </Col>
          <Clearfix />
        </Form>
      </div >
    );
  }
}

Checkout.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    influencer: state.influencer,
    alerts: state.alerts,
    item: state.cart.item,
    loading: state.settings.loading,
    countries: state.settings.countries,
    provinces: state.settings.provinces,
    estimated_delivery: state.settings.estimated_delivery,
    estimated_tax: state.settings.estimated_tax,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkout: (item, address, influencer, token) => {
      dispatch(checkout(item, address, influencer, token));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    getCountries: (token) => {
      dispatch(countries(token));
    },
    getProvinces: (country, token) => {
      dispatch(provinces(country, token));
    },
    estimateDelivery: (nid, location) => {
      dispatch(estimate_delivery(nid, location));
    },
    estimateTax: (item, address, influencer, token) => {
      dispatch(estimate_tax(item, address, influencer, token));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    },
    clearEstimateDelivery: () => {
      dispatch({ type: 'CLEAR_ESTIMATE_DELIVERY' });
    },
    clearEstimateTax: () => {
      dispatch({ type: 'CLEAR_ESTIMATE_TAX' });
    },
    no_shipping: () => {
      dispatch({
        type: 'NO_SHIPPING_TO_LOCATION',
        alerts: [{ msg: 'We currently do not have a seleced size available to ship to country. Please check back later.' }]
      });
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));

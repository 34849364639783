'use strict';
import 'regenerator-runtime/runtime';
import { applyMiddleware, compose } from 'redux';
import { createStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import logger from 'redux-logger';
import rootReducer from '../reducers';
import { routerMiddleware } from 'react-router-redux';
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from '../sagas'

const analytics = () => next => action => {

    return next(action);
};

export default function configureStore(initialState, history = '') {
    const composeEnhancers = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    // const logger = createLogger();
    // const sagaMiddleware = createSagaMiddleware();
    let applymid = {};


    let middlewares = [thunk, promise]; //, sagaMiddleware

    if (!process.env.PRODUCTION) {
        // middlewares.push(logger);
    }

    if (history) {
        middlewares.push(routerMiddleware(history));
        middlewares.push(analytics);
        // applymid = applyMiddleware(thunk, promise, logger, historyRouterMiddleware);
    } else {
        // applymid = applyMiddleware(thunk, promise, logger);
    }
    applymid = applyMiddleware(...middlewares);

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applymid),
        // applyMiddleware(sagaMiddleware)
    )
    // sagaMiddleware.run(rootSaga);

    if (module.hot) {
        // Enable hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}
